import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="DiMarzio - Desarrollo de tienda online en Drupal" description="DiMarzio es uno de los fabricantes de pastillas para guitarra más conocidos del mundo, para el que desarrollamos su sitio web con tienda online para sus pastillas."/>
      <section className="jumbotron pt-3 pb-6 mb-0" style={{backgroundColor: '#FFE1D0'}}>
        <div className="hero-zone container">
          <div className="hero-text row justify-content-md-center">
            <div className="col-lg-6">
              <h1 className="font-weight-bold diplay-4">DiMarzio</h1>
              <p className="h3">Desarrollo web para cliente lider en EEUU fabricante de pastillas y hardware para guitarras.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-3 bg-light text-left" id="features">
        <div className="container pb-5">
          <div className="row text-left">
            <div className="col-md-8 col-sm-12">
              <span className="badge badge-pill badge-secondary p-2 mr-2 mb-4">Desarrollo Drupal</span>
              <h2>
                <strong className="text-left">Puntos clave</strong>
              </h2>
              <div className="lead pt-4">
                <strong className="texto-destacado">Herramienta JS para encontrar la pastilla adecuada.</strong>
                <p>Se implementó en Javascript la herramienta de "Pickup picker" para ayudar a los clientes a elegir la pastilla adecuada para su guitarra, donde los adminstradores del sitio pueden desde el back-office administrar los criterios y resultados.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Localización de distribuidores por dirección / código postal.</strong>
                <p>Se creó una herramienta de busqueda de distruibidores en base al código postal, integrada totalmente con Google Maps.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Integración con Youtube.</strong>
                <p>Todos los artistas que colaboran con Dimarzio suben a Youtube video tocando la guitarra usando pastillas Dimarzio. Estos videos se importan automáticamente a la web, donde los adminsitradores puedes añadirle un título y descripción personalizada. </p>
              </div>
              <div className="lead pt-4 pb-5">
                <strong className="texto-destacado">Migración de productos desde CSV.</strong>
                <p>Los gestores de almacen y de stock gestionan el material a traves de Excel, por lo que se creó un importador web CSV para poder sincronizar periódicamente los productos en la web.</p>
              </div>
            </div>
            <div className="col-md-3 offset-md-0 offset-lg-1 col-sm-12">
              <div className="colaboradores">
                <h2 className="pt-lg-5">
                  <strong className="text-left pt-0">Colaboradores</strong>
                </h2>
              </div>
              <p className="pt-3 client-desc">En este proyecto colaboramos con <a href="https:avatarnewyork.com/" target="_blank" rel="noopener noreferrer">Avatar New York</a>, que llevaron la gestión del proyecto y del cliente.</p>
              <div className="cliente-proyecto">
                <h2 className="pt-5">
                  <strong className="text-left pt-0">Cliente</strong>
                </h2>
              </div>
              <p className="pt-3 client-desc">Fabricante de los Estados Unidos conocido por sus pastillas de guitarra de reemplazo directo y también produce varios accesorios de guitarra, como cables, correas y hardware.</p>
              <div className="btn-project">
                <a className="btn mr-4 btn-lg btn-outline-primary" href="https://www.dimarzio.com/">Sitio web →</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="jumbotron pb-5 text-left bg-white pt-0 mt-n6" id="features">
        <div className="container">
          <div className="row text-left">
            <div className="col-md-12 col-12 col-sm-12">
              <h2 className="pt-4 pb-4">
                <strong>El proyecto en imágenes</strong>
              </h2>
            </div>
            <div className='embed-responsive embed-responsive-16by9'>
               <iframe className="project-video embed-responsive-item" title="Video" width={1200} height={674} src="https://player.vimeo.com/video/412514371?autoplay=1&loop=1&autopause=0&muted=1&background=1"></iframe>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProjectHelse
